<template>
  <div class="e-content">
    <div id="FolderStructure">
      <h1>Folder Structure</h1>
      <p>eneterBlcok 사이트의 파일을 다운로드한 후 eb-frontend 폴더로 들어가면 다음과 같은 폴더 구조를 보여줍니다.
      </p>
      <b-card>
        <div class="card-code">
        <pre class="e-prism-text"><code>
          {{ textFolderStructure }}
          </code>
        </pre>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText} from 'bootstrap-vue'
import {textFolderStructure} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCard,
    BCardText,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['FolderStructure'],
      textFolderStructure,
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
    console.log('ddd', this.$refs.Routing)
  },
}
</script>
<style lang="scss" scoped>
.card-code {
  pre[class='e-prism-text'] {
    padding: 5px 20px;
    max-height: 100%;
    border-radius: 0.5rem;
  }
}
</style>

